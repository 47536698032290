<template>
  <div style="max-width: 96vw">
    <DataGridBannerMasterDetail
      :tableTitle="title"
      :dataSet="dataset"
      :columns="columns"
      :banneredColumnHeaders="banneredColumnHeaders"
      :banneredColumns="banneredColumns"
      :userMasterDetail="userMasterDetail"
      :masterDetailTemplateName="masterDetailTemplateName"
      :height="height"
    />
  </div>
</template>

<script>
import DataGridBannerMasterDetail from '@/components/tables/ChildTableContainers/DataGridBannerMasterDetail'
import { tableDataSetParser } from '@/utils/DataGridTableUtils'

export default {
  name: 'tableFieldSamples',
  props: ['rowData'],
  components: {
    DataGridBannerMasterDetail
  },
  data () {
    return {
      title: 'Field samples',
      dataset: null,
      columns: null,
      banneredColumnHeaders: null,
      banneredColumns: null,
      userMasterDetail: false,
      masterDetailTemplateName: null
    }
  },
  computed: {
    height () {
      return ((screen.height / 100) * 40).toString()
    }
  },
  async mounted () {
    // These parameters should be moved to properies
    let bannerIdentifier = null
    let connectionCharacter = null
    let bannerTitleIdentifier = null
    let translationsGroup = 'subSamples'
    const shownColumns = [
      'MEASUREMENTPOINTNAME',
      'FIELDSAMPLENAME',
      'FIELDSAMPLEFROM',
      'FIELDSAMPLETO',
      'FIELDSAMPLEDATE',
      'FIELDSAMPLETYPE',
      'FIELDSAMPLEBARCODE',
      'FIELDSAMPLESEEVESIZE',
      'FIELDSAMPLEASBESTOSSAMPLETYPE',
      'FIELDSAMPLEWEIGHT',
      'FIELDSAMPLEWEIGHTDEBRIS',
      'FIELDSAMPLEWEIGHTASBESTOS',
      'FIELDSAMPLEWEIGHTFINEFRACTION',
      'FIELDSAMPLEASBESTOSPIECES',
      'LAYERFROM',
      'LAYERTO',
      'LAYERTEXTURE',
      'LAYERPHYSICALOBSERVATIONS',
      'LAYERREMARKS'
    ]
    this.dataset = await this.$store.dispatch('getAllSubSamples', {
      projectId: this.rowData.data.ProjectID,
      sampleGUID: this.rowData.data.SampleGUID
    })
    if (this.dataset) {
      let parsedData = tableDataSetParser.Bannered(
        bannerIdentifier,
        connectionCharacter,
        bannerTitleIdentifier,
        translationsGroup,
        this.dataset
      )
      this.columns = parsedData.columns.filter((col) => {
        return !col.dataField.toString().toUpperCase().includes('GUID')
      })
      this.columns = this.columns.filter((col) => {
        return shownColumns.includes(col.dataField.toString().toUpperCase())
      })
      this.banneredColumnHeaders = parsedData.groups
      this.banneredColumns = parsedData.groupColumns
    }
  }
}
</script>

<style scoped>
</style>
