<template>
    <div style="max-width: 96vw;">
  <DataGridBannerMasterDetail
    :tableTitle="title"
    :dataSet="dataset"
    :columns="columns"
    :banneredColumnHeaders="banneredColumnHeaders"
    :banneredColumns="banneredColumns"
    :userMasterDetail="userMasterDetail"
    :masterDetailTemplateName="masterDetailTemplateName"
    :height="height"
  />
  </div>
</template>

<script>
import DataGridBannerMasterDetail from '@/components/tables/ChildTableContainers/DataGridBannerMasterDetail'
import { tableDataSetParser } from '@/utils/DataGridTableUtils'

export default {
  name: 'tableComponentResults',
  props: ['rowData'],
  components: {
    DataGridBannerMasterDetail
  },
  data () {
    return {
      title: 'Component Groups',
      dataset: null,
      columns: null,
      banneredColumnHeaders: null,
      banneredColumns: null,
      userMasterDetail: false,
      masterDetailTemplateName: null
    }
  },
  computed: {
    height () {
      return (screen.height / 100 * 40).toString()
    }
  },
  async mounted () {
    // These parameters should be moved to properies
    let bannerIdentifier = 'TestSubframeworkID'
    let connectionCharacter = '_'
    let bannerTitleIdentifier = 'TestSubframework'
    let translationsGroup = 'AnalysesResults'
    this.dataset = await this.$store.dispatch('GetAllResultsWithConclusions', { projectId: this.rowData.data.ProjectID, sampleGUID: this.rowData.data.SampleGUID })
    if (this.dataset) {
      this.dataset.forEach(result => {
        if (!result.ResultStandardisedValue || !result.ResultValue) return

        let amountOfSignificantsResultValue = result.ResultValue.toString().split('.')[1]
        amountOfSignificantsResultValue = (amountOfSignificantsResultValue) ? amountOfSignificantsResultValue.length : 0

        let amountOfSignificantsResultStandardisedValue = result.ResultStandardisedValue.toString().split('.')[1]
        amountOfSignificantsResultStandardisedValue = (amountOfSignificantsResultStandardisedValue) ? amountOfSignificantsResultStandardisedValue.length : 0

        if (amountOfSignificantsResultStandardisedValue <= amountOfSignificantsResultValue) return

        result.ResultStandardisedValue = parseFloat(result.ResultStandardisedValue.toString()).toFixed(amountOfSignificantsResultValue)
      })
      let parsedData = tableDataSetParser.Bannered(bannerIdentifier, connectionCharacter, bannerTitleIdentifier, translationsGroup, this.dataset)
      this.columns = parsedData.columns
      this.banneredColumnHeaders = parsedData.groups
      this.banneredColumns = parsedData.groupColumns
    }
  }
}
</script>

<style scoped>
</style>
